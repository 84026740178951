import { AfterViewInit, Component, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
import Table from 'src/smoothr-web-app-core/models/Table';
import { MatSnackBar } from '@angular/material/snack-bar';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { AppComponent } from 'src/app/app.component';
import { numberToCurrency, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { ShowSelectModalComponent, SHOW_ORDER_MODAL } from '../show-order-modal/show-select-modal.component';
import { MenuPage } from 'src/app/pages/menu/menu.page';
import { Router } from '@angular/router';
import { AuthLoginResponse } from 'src/smoothr-web-app-core/models/AuthLoginResponse';
import { OrderStatus } from 'src/smoothr-web-app-core/enums/OrderStatus';

@Component({
	selector: 'app-select-employee-table',
	templateUrl: './select-table-employee.component.html',
	styleUrls: ['./select-table-employee.component.scss'],
})
export class SelectTableEmployeeComponent implements AfterViewInit {
	order: Order;
	venue: Venue;
	tables: Table[] = [];
	selectedTable: Table;
	loading = false;
	authLogin: AuthLoginResponse;

	constructor(private modalCtrl: ModalController, private snackbarCtrl: MatSnackBar, private cdr: ChangeDetectorRef, private router: Router) { }

	static async show(modalCtrl: ModalController, order: Order, venue: Venue, authLogin: AuthLoginResponse): Promise<Table> {
		const modal = await modalCtrl.create({
			component: SelectTableEmployeeComponent,
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',

			componentProps: {
				order,
				venue,
				authLogin
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		console.log(authLogin)
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngAfterViewInit() {
		this.getTables();
	}
	async getTables() {
		this.loading = true;
		this.cdr.detectChanges()
		try {
			await this.loadTablesData();
			if (this.order && this.order.tableNumber !== 'preorder_table') {
				const foundTable = this.tables.find(table => table.number === this.order.tableNumber);
				if (foundTable) {
					this.selectedTable = foundTable;
				}
			}
			this.loading = false;
			this.cdr.detectChanges()

		} catch (e) {
			this.snackbarCtrl.open('Fehler beim Abrufen der Tabelle, bitte versuchen Sie es später', null, {
				duration: 2000,
			});
			this.loading = false;
			this.cdr.detectChanges()
		}
	}
	async loadTablesData() {
		const tables = (await Api.getTablesByVenue(this.venue._id)).data.filter(
			table => table.number !== 'system_table' && table.number !== 'preorder_table'
		);
		let listOrders = (await Api.getOrdersFiltered(this.venue._id)).data
		listOrders = listOrders.filter(item => item.status !== OrderStatus.DONE && item.preorder?.companyName == 'employee')
		console.log(listOrders)
		this.tables = ([...tables] as any).map(it => {
			it.tableOrder = []
			listOrders.forEach(order => {
				if (it._id === order.table) {
					it.tableOrder.push(order)
				}
			});
			return it;
		})
		
	}
	async selectItem(item: any) {
		let canSelect = true;
		let listOrders = (await Api.getOrdersFiltered(this.venue._id)).data;
		listOrders = listOrders.filter(item => item.status !== OrderStatus.DONE);
		listOrders.forEach( async order => {
			if (item._id === order.table && this.authLogin.name !== item.tableOrder[0]?.preorder?.name) {
				this.snackbarCtrl.open(`This table was already selected`, null, {
					duration: 2000,
				});
				canSelect = false
				await this.loadTablesData()
				return;
			}
		})
		console.log(this.authLogin, item)
		if(this.authLogin && item && item.tableOrder.length == 0 && canSelect) {
			console.log('here', item)
			this.selectedTable = item;
			return;
		}
		// if (this.authLogin && item && item.tableOrder.length > 0 && this.authLogin?.name != item.tableOrder[0].preorder?.name) {
		
		// }
	}
	async goBack() {
		await this.modalCtrl.dismiss();
	}
	async continue() {
		await this.modalCtrl.dismiss(this.selectedTable);
	}
	tableOrderDescription(tableOrders: Order[]) {
		let price = 0;
		if (tableOrders.length == 0) {
			return;
		}
		tableOrders.forEach(element => {
			price = OrderUtils.orderTotalPrice(element, true, true) + price;
		})
		return `${tableOrders.length} Orders, Total ${numberToCurrency(price, tableOrders[0].currency)}`;
	}
	async showOrders(tableOrders: Order[], table: Table) {
		if(this.authLogin?.name != tableOrders[0].preorder?.name) {
			return;
		}
		const result = await ShowSelectModalComponent.show(this.modalCtrl, tableOrders, this.venue);
		if (result === SHOW_ORDER_MODAL.ADD_ORDER) {
			await this.modalCtrl.dismiss(table);
			return;
		}
		if (result === SHOW_ORDER_MODAL.CLOSE_ORDER) {
			tableOrders.forEach(async itemOrder => {
				await Api.updateOrder(itemOrder._id, OrderStatus.DONE);
			})
			await this.getTables()
			// await this.modalCtrl.dismiss(table);
			return;
		}

	}
}
