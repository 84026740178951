import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const madoCustomerGroup = 'mado';
export const customerGroup = madoCustomerGroup;

const supportEmail = {
	mado: 'mado@smoothr.de',
};
const firebaseConfig = {
	mado: {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:717e964d04053cd8cbfff0',
		measurementId: 'G-RY1H9M65WZ',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBxctZyYOPXBxP8pv-IsX0F3y16TB3nCFU',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	gaId: '',
	...apiEnvironment,
};
