import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const madoCustomerGroup = 'mado';
export const customerGroup = madoCustomerGroup;

const supportEmail = {
	mado: 'mado@smoothr.de',
};
const firebaseConfig = {
	mado: {
		apiKey: 'AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:8c68e1dfffd9cd98e0fe06',
		measurementId: 'G-ZVX7VJ362B',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAUJ0yCbuGUs9lDKehj-VGlD0oeU3XO__A',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	gaId: '',
	...apiEnvironment,
};
