import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
import Table from 'src/smoothr-web-app-core/models/Table';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { AppComponent } from 'src/app/app.component';
import { getPrice, numberToCurrency, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';

export enum SHOW_ORDER_MODAL {
	CLOSE_ORDER = 'close_order',
	ADD_ORDER = 'add_order'
}


@Component({
	selector: 'app-select-employee-table',
	templateUrl: './show-select-modal.component.html',
	styleUrls: ['./show-select-modal.component.scss'],
})
export class ShowSelectModalComponent {
	orders: Order[] = [];
	venue: Venue;
	numberToCurrency = numberToCurrency;
	orderUtils = OrderUtils;
	constructor(private modalCtrl: ModalController, private translate: TranslateService) { }

	static async show(modalCtrl: ModalController, orders: Order[], venue: Venue): Promise<SHOW_ORDER_MODAL> {
		const modal = await modalCtrl.create({
			component: ShowSelectModalComponent,
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			componentProps: {
				orders,
				venue,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}
	close() {
		this.modalCtrl.dismiss(SHOW_ORDER_MODAL.CLOSE_ORDER);
	}
	addOrder() {
		this.modalCtrl.dismiss(SHOW_ORDER_MODAL.ADD_ORDER);		
	}
	goBack() {
		this.modalCtrl.dismiss();
	}
	title() {
		if(!this.venue && this.orders.length > 0) {
			return ''
		} else {
			return `${this.translate.instant('show_select_modal.title') + this.orders[0].tableNumber}`
		}
	}
	hiddenInCart(article: Article): boolean {
		return (
			article.tags &&
			article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined
		);
	}
	priceOfOption(
		articleGroup: ArticleGroup,
		articleOption: ArticleOption,
		order: Order
	): number {
		const price = getPrice(
			articleOption.article,
			OrderType.PREORDER,
			order.preorder.type
		);
		if (
			OrderUtils.isBogoOrFreeArticlePromo(order) &&
			articleGroup.isPromo
		) {
			return 0;
		}
		return price * articleOption.quantity;
	}
 }
